import React, { useState } from 'react';
import Navigation from "../../../../components/Navigation/Navigation";
import Layout from "../../../../hoc/Layout/Layout";
import 'react-rangeslider/lib/index.css'
import Slider from "react-rangeslider";
import InnerLayout from "../../../../hoc/InnerLayout/InnerLayout";
import { connect } from "react-redux";
import { setRetireAge } from "../../../../store/actions/aboutYou";
import './RetireAge.module.scss'
import { Helmet } from "react-helmet";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const RetireAge = ( props ) => {
    const [ value, setValue ] = useState( props.retireAge ? props.retireAge : 65);
    const titles = {
        layoutTitle: 'TELL US ABOUT YOURSELF',
        sectionTitle: 'At what age do you want to retire?',
    }

    const horizontalLabels = {
        0: `0 years`,
        100: `71 years`
    }

    const onChangeRangeHandler = (value) => {
        props.onChange(value)
        setValue(value)
    }

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep1={(2/7)*100}
            active={'step1'}
        >

            <Helmet>
                <title>Retirement age</title>
            </Helmet>

            <InnerLayout
                sectionTitle={titles.sectionTitle}
                modalContent='retireAge'
            >
                <Slider
                    min={46}
                    max={71}
                    value={value}
                    tooltip={false}
                    handleLabel={value.toString()}
                    labels={horizontalLabels}
                    onChange={value => onChangeRangeHandler(value)}
                />
            </InnerLayout>

             <Navigation
                 isCompleted={true}
                 next={retirementCalcLinks.yearsOfIncome}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        retireAge: state.aboutYou.retireAge,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        onChange: val => dispatch(setRetireAge(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RetireAge);